import _ from 'lodash';
import type { ITablePropertyValuesService } from './smart-groups-filter-values.service';
import type { IPropertyDefinition } from '../../lib/config-hierarchy';
import type { IQueryFilters, ITablePropertyValues } from '../../lib/types';
import { isObject } from '../../lib/utils';
import { compilePropertyFilters, decompileQueryFilters } from './smart-groups.helper';
import type { IFilterPopupModel } from './smart-groups.service';
import type { IExportedPropertyView, IPropertyView, PropertyView } from './smart-group-filter-property-item.directive';
import type { IOutsideElementClick } from '../../directives/outside-element-click.directive';
import { SidebarModel, SidebarToggleModel } from '../../components/sidebar';
import { HierarchyService } from '../hierarchy';
import { ConfigAPI } from '../../lib/config-api';
import { TablePropertyAvailableTablesKeys } from './smart-groups-filter-values.service';
import { AngularInjected } from '../../lib/angular';
import { IPromise } from 'angular';

const getConfig = () =>
    ConfigAPI.get()
        .then(api => Promise.all([api.user.get(), api.organization.get()]))
        .then(([user, organization]) => {
            const verifyLabel = (config: unknown) => {
                if (isObject(config) && 'label' in config && typeof config.label === 'string') return config.label;
                return null;
            };

            return {
                stores: {
                    label: verifyLabel(user.stores) ?? verifyLabel(organization.stores),
                },
                items: {
                    label: verifyLabel(user.items) ?? verifyLabel(organization.items),
                },
            };
        });

const getProperties = () =>
    HierarchyService()
        .fetch()
        .then(tableProperties => {
            const propertiesByDescriptor: Record<string, IPropertyDefinition[]> = {};
            for (const [key, descriptorProperties] of Object.entries(tableProperties.segments)) {
                const properties = descriptorProperties.filter(
                    ({ table }) => table && TablePropertyAvailableTablesKeys.includes(table),
                );
                propertiesByDescriptor[key] = properties;
            }
            return propertiesByDescriptor;
        });

export const fetchDescriptors = () => {
    return Promise.all([getConfig(), getProperties()]).then(([config, propertiesByDescriptor]) => {
        return {
            stores: {
                id: 'stores',
                tables: ['stores', 'acquisitions', 'employees', 'accounts', 'warehouses', 'customers'],
                label: config.stores.label ?? 'Store Filter',
                instructions: `
                This filter is used to find customers that shopped at one or more stores.
                `,
                hierarchy: propertiesByDescriptor.stores,
            },
            items: {
                id: 'items',
                tables: ['items', 'transaction_items', 'item_timeseries', 'gift_cards', 'demand_items'],
                label: config.items.label ?? 'Item Filter',
                instructions: `
                This filter allows you to find customers that have
                purchased a particular type of item, or an item of
                a certain category.
                `,
                hierarchy: propertiesByDescriptor.items,
            },
        };
    });
};

export type ISegmentsPropertiesService = AngularInjected<typeof SegmentsPropertiesServiceFactory>;
export const SegmentsPropertiesServiceFactory = () => [
    '$q',
    'TablePropertyValues',
    function SegmentsPropertiesService($q: angular.IQService, TablePropertyValues: ITablePropertyValuesService) {
        return {
            fetchDescriptors: fetchDescriptors,
            fetchProperties: (descriptorId: string) => {
                if (descriptorId !== 'stores' && descriptorId !== 'items') {
                    throw new Error(`Unknown descriptor id: ${descriptorId}`);
                }
                return $q.when(fetchDescriptors()).then(result => {
                    const properties = result[descriptorId].hierarchy ?? [];
                    const tables = _.compact(_.uniq(properties.map(property => property.table)));

                    return { properties, tables };
                });
            },
            fetchPropertyValues: (
                queryFilters: IQueryFilters,
                property: IPropertyDefinition,
            ): IPromise<null | ISegmentPropertyFilter[]> => {
                const { table, column } = property;
                if (!table || !column) return $q.when(null);
                const tableFilters = _.cloneDeep(queryFilters[table]);
                return TablePropertyValues.fetch(table, column, tableFilters).then(
                    (results: ITablePropertyValues[]) => {
                        return results.reduce((acc: ISegmentPropertyFilter[], result) => {
                            const id = result.value;
                            if (typeof result.value !== 'string') return acc;
                            const label = id.replace(/'/g, '’');
                            const search = id.trim().toLowerCase();
                            acc.push({
                                id,
                                label,
                                search,
                                // We need to replace ' because otherwise we get some kind
                                // of escaping error, because we use `ng-bind-html`.
                                ogLabel: label,
                                count: result.count,
                                excluded: false,
                                selected: false,
                            });
                            return acc;
                        }, []);
                    },
                );
            },
        };
    },
];

export interface ISegmentsViewProps {
    properties: IPropertyDefinition[];
    tables: string[];
    queryFilters: IQueryFilters;
    options?: {
        compact?: boolean;
    };
}

function isTableColumnFilter(value: unknown): value is { $and: unknown[] } {
    return isObject(value) && Array.isArray(value.$and);
}

export const isFilterMissing = (tables: string[], filters: unknown) => {
    if (!isObject(filters)) return true;
    return tables.every((table: string) => {
        const filter = filters[table];
        return !isTableColumnFilter(filter) || filter.$and.length === 0;
    });
};

export interface ISegmentPropertyFilter {
    id: string;
    search: string;
    label: string;
    selected: boolean;
    excluded: boolean;
    ogLabel?: string;
    count?: number;
}
export interface ISegmentProperty extends IPropertyDefinition {
    values?: ISegmentPropertyFilter[] | null | undefined;
}

export type ISegmentsViewFactory = AngularInjected<typeof SegmentsViewFactory>;
export type ISegmentsView = InstanceType<ISegmentsViewFactory>;

export const SegmentsViewFactory = () => [
    'PropertyView',
    function SegmentsViewFn(PropertyView: PropertyView) {
        class SegmentsView {
            properties: IPropertyDefinition[];
            tables: string[];
            queryFilters: IQueryFilters;
            isUnsaved = false;
            sidebarModel: null | SidebarModel = null;

            availableProperties: IPropertyDefinition[] = [];
            selectedPropertiesViews: IPropertyView[] = [];

            constructor(viewProps: ISegmentsViewProps) {
                this.properties = viewProps.properties;
                this.tables = viewProps.tables;
                this.queryFilters = viewProps.queryFilters;

                this.initPropertyFilters(this.queryFilters);
                this.initSidebar();
            }

            initSidebar() {
                this.sidebarModel = (() => {
                    if (this.properties.length === 0) return null;

                    return new SidebarModel({
                        properties: {
                            available: [...this.availableProperties],
                            selected: [],
                            options: {
                                multipleSelection: false,
                                chevron: true,
                                allOpen: true,
                                disableBackButton: true,
                                hideTitle: true,
                            },
                            selectProperty: property => {
                                if (property[0]) this.selectProperty(property[0].id);
                            },
                        },
                        options: {
                            hideTabs: true,
                            disableResize: true,
                        },
                        toggle: new SidebarToggleModel({ tab: 'properties', isOpen: true }),
                    });
                })();
            }

            haveQueryFilterChanged(queryFilters: IQueryFilters) {
                return !this.tables.every(table =>
                    _.isEqual(this.queryFilters[table] ?? {}, queryFilters[table] ?? {}),
                );
            }

            selectProperty(id: string) {
                const property = this.availableProperties.find(p => p.id === id);
                if (!property) return;
                const propertyIndex = this.availableProperties.indexOf(property);
                this.availableProperties.splice(propertyIndex, 1);

                const selectedPropertyFilters = _.compact(
                    this.selectedPropertiesViews.map(s => s.getSelectedPropertyFilters()),
                );
                const queryFilters = compilePropertyFilters(selectedPropertyFilters, this.tables);

                // Create new Property in Loading State
                const view = new PropertyView({
                    property,
                    queryFilters,
                    onPropertyFilterClick: this.onPropertyFilterClick.bind(this),
                    onClose: this.onCloseProperty.bind(this),
                });

                this.selectedPropertiesViews.push(view);
                this.updateSidebarModel();
            }

            updateSidebarModel() {
                if (this.sidebarModel) {
                    this.sidebarModel.properties.available = [...this.availableProperties];
                }
            }

            onPropertyFilterClick(changedPropertyView: IPropertyView) {
                // Properties Views To Update Values
                const propertyViewsToUpdate: IPropertyView[] = [];
                // Property Filters selected for the query
                const selectedPropertyFilters: IExportedPropertyView[] = [];

                this.selectedPropertiesViews.forEach(propertyView => {
                    const propertyViewSelectedPropertyFilters = propertyView.getSelectedPropertyFilters();
                    if (propertyViewSelectedPropertyFilters) {
                        selectedPropertyFilters.push(propertyViewSelectedPropertyFilters);
                    }

                    if (changedPropertyView !== propertyView) {
                        // Don't changed state of properties which use different tables.
                        if (changedPropertyView.property.table === propertyView.property.table) {
                            propertyViewsToUpdate.push(propertyView);
                        } else {
                            propertyView.sort();
                        }
                    }
                });

                const queryFilters = compilePropertyFilters(selectedPropertyFilters, this.tables);
                this.isUnsaved = this.haveQueryFilterChanged(queryFilters);

                propertyViewsToUpdate.forEach(propertyView => {
                    propertyView.updatePropertyValues(queryFilters);
                });
            }

            onCloseProperty(changedPropertyView: IPropertyView) {
                const propertyIndex = this.selectedPropertiesViews.findIndex(p => p === changedPropertyView);

                if (propertyIndex > -1) {
                    // remove from selectedPropertiesViews
                    this.selectedPropertiesViews.splice(propertyIndex, 1);
                    // Add closed Property in the correct properties order.
                    this.availableProperties = (() => {
                        const propertyIds = this.selectedPropertiesViews.map(p => p.property.id);
                        return this.properties.filter(p => !propertyIds.includes(p.id));
                    })();
                }

                // Don't update if no filters are selected in this property
                const selectedPropertyFilters = changedPropertyView.getSelectedPropertyFilters();
                if (selectedPropertyFilters && selectedPropertyFilters.values.length > 0) {
                    this.onPropertyFilterClick(changedPropertyView);
                }

                this.updateSidebarModel();
            }

            initPropertyFilters(queryFilters: IQueryFilters) {
                // If there are filters with not tables - Return
                if (isFilterMissing(this.tables, queryFilters)) {
                    this.availableProperties = [...this.properties];
                    return;
                }

                // Save Filters in View
                this.queryFilters = _.cloneDeep(queryFilters);
                const queryProperties = Array.from(decompileQueryFilters(queryFilters, this.properties, this.tables));

                this.availableProperties = (() => {
                    const propertyIds = queryProperties.map(p => p.id);
                    return this.properties.filter(p => !propertyIds.includes(p.id));
                })();

                if (queryProperties.length < 1) {
                    this.selectedPropertiesViews = [];
                    return;
                }

                this.selectedPropertiesViews = queryProperties.map(property => {
                    return new PropertyView({
                        property,
                        queryFilters: this.queryFilters,
                        onPropertyFilterClick: this.onPropertyFilterClick.bind(this),
                        onClose: this.onCloseProperty.bind(this),
                    });
                });
            }

            reset() {
                this.selectedPropertiesViews = [];
                this.availableProperties = [...this.properties];
                this.isUnsaved = this.haveQueryFilterChanged(compilePropertyFilters([], this.tables));
                this.updateSidebarModel();
            }

            getQuery(): IQueryFilters {
                const selectedPropertyFilters = _.compact(
                    this.selectedPropertiesViews.map(s => s.getSelectedPropertyFilters()),
                );

                return compilePropertyFilters(selectedPropertyFilters, this.tables);
            }
        }

        return SegmentsView;
    },
];

export type ISegmentsCompactViewFactory = AngularInjected<typeof SegmentsCompactViewFactory>;
export type ISegmentsCompactView = InstanceType<ISegmentsCompactViewFactory>;

export const SegmentsCompactViewFactory = () => [
    'PropertyView',
    function SegmentsCompactViewFn(PropertyView: PropertyView) {
        class SegmentsCompactView {
            properties: IPropertyDefinition[];
            tables: string[];
            queryFilters: IQueryFilters;
            isUnsaved = false;
            sidebarModel: null | SidebarModel = null;

            availableProperties: IPropertyDefinition[] = [];
            selectedPropertiesViews: IPropertyView[] = [];

            constructor(viewProps: ISegmentsViewProps) {
                this.properties = viewProps.properties;
                this.tables = viewProps.tables;
                this.queryFilters = viewProps.queryFilters;

                this.initPropertyFilters(this.queryFilters);
                this.initSidebar();
            }

            initSidebar() {
                this.sidebarModel = (() => {
                    if (this.properties.length === 0) return null;

                    return new SidebarModel({
                        properties: {
                            available: [...this.availableProperties],
                            selected: this.selectedPropertiesViews.map(p => p.property),
                            options: {
                                isMobile: true,
                                multipleSelection: true,
                                chevron: true,
                                allOpen: true,
                                disableBackButton: true,
                                hideTitle: true,
                            },
                            selectProperty: properties => {
                                this.selectProperties(properties.map(p => p.id));
                            },
                        },
                        options: {
                            hideTabs: true,
                            disableResize: true,
                        },
                        toggle: new SidebarToggleModel({ tab: 'properties', isOpen: true }),
                    });
                })();
            }

            haveQueryFilterChanged(queryFilters: IQueryFilters) {
                return !this.tables.every(table =>
                    _.isEqual(this.queryFilters[table] ?? {}, queryFilters[table] ?? {}),
                );
            }

            private addNewPropertyView(propertyToAdd: string) {
                const property = this.availableProperties.find(p => p.id === propertyToAdd);
                if (!property) return;
                const selectedPropertyFilters = _.compact(
                    this.selectedPropertiesViews.map(s => s.getSelectedPropertyFilters()),
                );
                const queryFilters = compilePropertyFilters(selectedPropertyFilters, this.tables);

                // Create new Property in Loading State
                const view = new PropertyView({
                    property,
                    queryFilters,
                    onPropertyFilterClick: this.onPropertyFilterClick.bind(this),
                    onClose: this.onCloseProperty.bind(this),
                    onBack: this.onBack.bind(this),
                });

                this.selectedPropertiesViews.push(view);
            }

            private onCloseProperty(propertyToClose: InstanceType<PropertyView> | string) {
                const propertyId = typeof propertyToClose === 'string' ? propertyToClose : propertyToClose.property.id;
                const property = this.availableProperties.find(p => p.id === propertyId);
                if (!property) return;
                const propertyIndex = this.selectedPropertiesViews.findIndex(p => p.property.id === propertyId);
                const changedPropertyView = this.selectedPropertiesViews[propertyIndex];
                if (!changedPropertyView) return;
                if (propertyIndex > -1) {
                    // remove from selectedPropertiesViews
                    this.selectedPropertiesViews.splice(propertyIndex, 1);
                }
                this.initSidebar();
            }

            selectProperties(ids: string[]) {
                const propertyId = ids[0];
                if (!propertyId) return;

                const propertyViewIndex = this.selectedPropertiesViews.findIndex(p => p.property.id === propertyId);

                if (propertyViewIndex === -1) {
                    this.addNewPropertyView(propertyId);
                } else {
                    if (propertyViewIndex + 1 !== this.selectedPropertiesViews.length) {
                        const propertyView = this.selectedPropertiesViews[propertyViewIndex];
                        if (propertyView) {
                            const selectedPropertiesViews = [
                                ...this.selectedPropertiesViews.slice(0, propertyViewIndex),
                                ...this.selectedPropertiesViews.slice(propertyViewIndex + 1),
                            ];
                            selectedPropertiesViews.push(propertyView);
                            this.selectedPropertiesViews = selectedPropertiesViews;
                        }
                    }
                }

                // Wait for render
                setTimeout(() => {
                    this.sidebarModel?.toggle.close();
                }, 100);
            }

            private onBack(propertyView: IPropertyView) {
                const selectedPropertyFilters = propertyView.getSelectedPropertyFilters();

                if (!selectedPropertyFilters || selectedPropertyFilters.values.length === 0) {
                    const propertyIndex = this.selectedPropertiesViews.findIndex(p => p === propertyView);
                    this.selectedPropertiesViews.splice(propertyIndex, 1);
                }

                this.initSidebar();
            }

            onPropertyFilterClick(changedPropertyView: IPropertyView) {
                // Properties Views To Update Values
                const propertyViewsToUpdate: IPropertyView[] = [];
                // Property Filters selected for the query
                const selectedPropertyFilters: IExportedPropertyView[] = [];

                this.selectedPropertiesViews.forEach(propertyView => {
                    const propertyViewSelectedPropertyFilters = propertyView.getSelectedPropertyFilters();
                    if (propertyViewSelectedPropertyFilters) {
                        selectedPropertyFilters.push(propertyViewSelectedPropertyFilters);
                    }

                    if (changedPropertyView !== propertyView) {
                        // Don't changed state of properties which use different tables.
                        if (changedPropertyView.property.table === propertyView.property.table) {
                            propertyViewsToUpdate.push(propertyView);
                        } else {
                            propertyView.sort();
                        }
                    }
                });

                const queryFilters = compilePropertyFilters(selectedPropertyFilters, this.tables);
                this.isUnsaved = this.haveQueryFilterChanged(queryFilters);

                propertyViewsToUpdate.forEach(propertyView => {
                    propertyView.updatePropertyValues(queryFilters);
                });
            }

            onRemoveProperty(changedPropertyView: IPropertyView) {
                const propertyIndex = this.selectedPropertiesViews.findIndex(p => p === changedPropertyView);

                if (propertyIndex > -1) {
                    // remove from selectedPropertiesViews
                    this.selectedPropertiesViews.splice(propertyIndex, 1);
                }

                // Don't update if no filters are selected in this property
                const selectedPropertyFilters = changedPropertyView.getSelectedPropertyFilters();
                if (selectedPropertyFilters && selectedPropertyFilters.values.length > 0) {
                    this.onPropertyFilterClick(changedPropertyView);
                }
            }

            initPropertyFilters(queryFilters: IQueryFilters) {
                // If there are filters with not tables - Return
                this.availableProperties = [...this.properties];
                if (isFilterMissing(this.tables, queryFilters)) {
                    return;
                }

                // Save Filters in View
                this.queryFilters = _.cloneDeep(queryFilters);
                const queryProperties = Array.from(decompileQueryFilters(queryFilters, this.properties, this.tables));

                this.availableProperties = [...this.properties];

                if (queryProperties.length < 1) {
                    this.selectedPropertiesViews = [];
                    return;
                }

                this.selectedPropertiesViews = queryProperties.map(property => {
                    return new PropertyView({
                        property,
                        queryFilters: this.queryFilters,
                        onPropertyFilterClick: this.onPropertyFilterClick.bind(this),
                        onClose: this.onCloseProperty.bind(this),
                        onBack: this.onBack.bind(this),
                    });
                });
            }

            reset() {
                this.selectedPropertiesViews = [];
                this.availableProperties = this.properties;
                this.isUnsaved = this.haveQueryFilterChanged(compilePropertyFilters([], this.tables));
                this.initSidebar();
            }

            close() {
                this.selectedPropertiesViews = [];
                this.availableProperties = this.properties;
                this.isUnsaved = this.haveQueryFilterChanged(compilePropertyFilters([], this.tables));
                this.sidebarModel?.toggle.close();
            }

            getQuery(): IQueryFilters {
                const selectedPropertyFilters = _.compact(
                    this.selectedPropertiesViews.map(s => s.getSelectedPropertyFilters()),
                );

                return compilePropertyFilters(selectedPropertyFilters, this.tables);
            }
        }

        return SegmentsCompactView;
    },
];

interface SegmentsDirectiveScope extends angular.IScope {
    view: ISegmentsView | null;
    popupTracker: angular.promisetracker.PromiseTracker;
    selectProperty: ($event: MouseEvent, id: string) => void;
    reset: () => void;
    save: () => void;
    cancel: () => void;
    popup: null | IFilterPopupModel;
}

export const SegmentsDirective = (module: angular.IModule) =>
    module.directive('segments', [
        '$document',
        '$timeout',
        'SegmentsPropertiesService',
        'SegmentsView',
        'promiseTracker',
        'OutsideElementClick',
        (
            $document: angular.IDocumentService,
            $timeout: angular.ITimeoutService,
            SegmentsPropertiesService: ISegmentsPropertiesService,
            SegmentsView: ISegmentsViewFactory,
            promiseTracker: angular.promisetracker.PromiseTrackerService,
            OutsideElementClick: IOutsideElementClick,
        ): angular.IDirective<SegmentsDirectiveScope> => ({
            restrict: 'E',
            scope: {
                popup: '=model',
                onSave: '&',
            },
            template: `
                <article class="modal smart-groups-filter-container animation-filter" ng-if="popup.state">
                    <div class="overlay"></div>
                    <article class="smart-groups-filter">
                        <header>
                            <h1>{{ popup.state.descriptor.label }}</h1>
                            <div class="button-modal-close" ng-click="cancel()">
                                <svg class="icon" viewBox="0 0 32 32"><polygon points="28.374,5.747 26.253,3.626 16,13.879 5.747,3.626 3.626,5.747 13.879,16 3.626,26.253 5.747,28.374 16,18.121 26.253,28.374 28.374,26.253 18.121,16 "></polygon></svg>
                            </div>
                        </header>
                        <main>
                            <div class="loadable" promise-tracker="popupTracker"></div>
                            <article>
                                <article class="smart-group-filter smart-group-filter-items">
                                    <article class="property-filters">
                                        <sidebar ng-if="view.sidebarModel" model="view.sidebarModel"></sidebar>
                                        <section class="item-property-filters" ng-class="{ loading: isLoading }">
                                            <segment-property-filter
                                                ng-repeat="property in view.selectedPropertiesViews"
                                                model="property">
                                            </segment-property-filter>
                                        </section>
                                    </article>
                                </article>
                            </article>
                        </main>
                         <div class="sidepanel">
                            <property-filter-list model="view.selectedPropertiesViews"></property-filter-list>
                            <footer>
                                <div class="button-reset" ng-click="reset()">reset</div>
                                <div class="button-save" ng-click="save()" ng-class="{ unsaved: view.isUnsaved }">save</div>
                            </footer>
                        </div>
                    </article>
                </article>
            `,
            link: function SmartGroupsFilterDirectiveLink(scope, $element) {
                scope.view = null;
                scope.popupTracker = promiseTracker();

                const getItemPropertyFiltersSectionEl = (): null | Element => {
                    return $element[0]?.getElementsByClassName('item-property-filters').item(0) ?? null;
                };

                const initComponentState = (popupState: NonNullable<IFilterPopupModel['state']>) => {
                    return SegmentsPropertiesService.fetchProperties(popupState.descriptor.id).then(
                        segmentsPropertiesData => {
                            scope.view = new SegmentsView({
                                properties: segmentsPropertiesData.properties,
                                tables: segmentsPropertiesData.tables,
                                queryFilters: _.cloneDeep(popupState.filters),
                            });
                        },
                    );
                };

                scope.selectProperty = (event: MouseEvent, id: string) => {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    scope.view?.selectProperty(id);
                    void $timeout(() => {
                        const element = getItemPropertyFiltersSectionEl();
                        if (element) element.scrollLeft += element.scrollWidth;
                    }, 300);
                };

                const closePopup = () => {
                    scope.popup?.close();
                    scope.view = null;
                };

                scope.reset = () => scope.view?.reset();
                scope.cancel = () => {
                    scope.view?.reset();
                    closePopup();
                };
                scope.save = () => {
                    if (!scope.view) return;

                    const queryFilters = scope.view.getQuery();

                    scope.view.reset();
                    scope.popup?.onSave && scope.popup.onSave(queryFilters);
                    closePopup();
                };

                const closeOnEscapeKey = (event: JQuery.Event) => {
                    if (event.key === 'Escape' || event.which === 27) {
                        scope.cancel();
                    }
                };
                let escapeKeyWatcherCleanup: () => void = () => {};
                const initEscapeKeyWatcher = () => {
                    escapeKeyWatcherCleanup();
                    $document.on('keypress keydown', closeOnEscapeKey);
                    return () => $document.off('keypress keydown', closeOnEscapeKey);
                };
                let outsideElementClickCleanup: () => void = () => {};

                scope.$watch('popup.state', (state: IFilterPopupModel['state']) => {
                    outsideElementClickCleanup();
                    escapeKeyWatcherCleanup();
                    if (!state) return;
                    scope.view?.reset();
                    scope.popupTracker.addPromise(initComponentState(state));
                    void $timeout(() => {
                        const el = $(
                            '.smart-groups-filter-container .smart-groups-filter, .smart-group .smart-group-filters',
                        );
                        outsideElementClickCleanup = OutsideElementClick(scope, el, () => scope.cancel());
                        escapeKeyWatcherCleanup = initEscapeKeyWatcher();
                    });
                });
            },
        }),
    ]);

interface SegmentsCompactDirectiveScope extends angular.IScope {
    view: ISegmentsCompactView | null;
    popupTracker: angular.promisetracker.PromiseTracker;
    selectProperty: ($event: MouseEvent, id: string) => void;
    reset: () => void;
    save: () => void;
    cancel: () => void;
    popup: null | IFilterPopupModel;
}

export const SegmentsCompactDirective = (module: angular.IModule) =>
    module.directive('segmentsCompact', [
        '$document',
        '$timeout',
        'SegmentsPropertiesService',
        'SegmentsCompactView',
        'promiseTracker',
        'OutsideElementClick',
        (
            $document: angular.IDocumentService,
            $timeout: angular.ITimeoutService,
            SegmentsPropertiesService: ISegmentsPropertiesService,
            SegmentsCompactView: ISegmentsCompactViewFactory,
            promiseTracker: angular.promisetracker.PromiseTrackerService,
            OutsideElementClick: IOutsideElementClick,
        ): angular.IDirective<SegmentsCompactDirectiveScope> => ({
            restrict: 'E',
            scope: {
                popup: '=model',
                onSave: '&',
            },
            template: `
                <article class="modal smart-groups-filter-container compact animation-filter" ng-if="popup.state">
                    <div class="overlay"></div>
                    <article class="smart-groups-filter compact">
                        <header>
                            <h1>{{ popup.state.descriptor.label }}</h1>
                            <div class="button-modal-close" ng-click="cancel()">
                                <svg class="icon" viewBox="0 0 32 32"><polygon points="28.374,5.747 26.253,3.626 16,13.879 5.747,3.626 3.626,5.747 13.879,16 3.626,26.253 5.747,28.374 16,18.121 26.253,28.374 28.374,26.253 18.121,16 "></polygon></svg>
                            </div>
                        </header>
                        <main>
                            <div class="loadable" promise-tracker="popupTracker"></div>
                            <article class="smart-group-filter smart-group-filter-items compact">
                                <article class="property-filters">
                                    <sidebar ng-if="view.sidebarModel" model="view.sidebarModel"></sidebar>
                                    <section class="item-property-filters">
                                        <segment-property-filter
                                            ng-if="view.selectedPropertiesViews.length > 0"
                                            model="view.selectedPropertiesViews[view.selectedPropertiesViews.length - 1]">
                                        </segment-property-filter>
                                    </section>
                                </article>
                            </article>
                        </main>
                        <footer>
                            <div class="button-reset" ng-click="reset()">reset</div>
                            <div class="button-save" ng-click="save()" ng-class="{ unsaved: view.isUnsaved }">save</div>
                        </footer>
                    </article>
                </article>
            `,
            link: function SmartGroupsFilterDirectiveLink(scope, $element) {
                scope.view = null;
                scope.popupTracker = promiseTracker();

                const initComponentState = (popupState: NonNullable<IFilterPopupModel['state']>) => {
                    return SegmentsPropertiesService.fetchProperties(popupState.descriptor.id).then(
                        segmentsPropertiesData => {
                            scope.view = new SegmentsCompactView({
                                properties: segmentsPropertiesData.properties,
                                tables: segmentsPropertiesData.tables,
                                queryFilters: _.cloneDeep(popupState.filters),
                            });
                        },
                    );
                };

                const closePopup = () => {
                    scope.popup?.close();
                    scope.view = null;
                };

                scope.reset = () => scope.view?.reset();
                scope.cancel = () => {
                    scope.view?.reset();
                    closePopup();
                };
                scope.save = () => {
                    if (!scope.view) return;

                    const queryFilters = scope.view.getQuery();

                    scope.view.reset();
                    scope.popup?.onSave && scope.popup.onSave(queryFilters);
                    closePopup();
                };

                const closeOnEscapeKey = (event: JQuery.Event) => {
                    if (event.key === 'Escape' || event.which === 27) {
                        scope.cancel();
                    }
                };
                let escapeKeyWatcherCleanup: () => void = () => {};
                const initEscapeKeyWatcher = () => {
                    escapeKeyWatcherCleanup();
                    $document.on('keypress keydown', closeOnEscapeKey);
                    return () => $document.off('keypress keydown', closeOnEscapeKey);
                };
                let outsideElementClickCleanup: () => void = () => {};
                let outsideSidebarElementClick: () => void = () => {};

                scope.$watch('popup.state', (state: IFilterPopupModel['state']) => {
                    outsideElementClickCleanup();
                    escapeKeyWatcherCleanup();
                    outsideSidebarElementClick();
                    if (!state) return;
                    scope.popupTracker.addPromise(initComponentState(state));
                    void $timeout(() => {
                        const el = $('.smart-groups-filter-container .smart-groups-filter');
                        outsideElementClickCleanup = OutsideElementClick(scope, el, () => scope.cancel());
                        if (scope.view?.sidebarModel && scope.view.selectedPropertiesViews.length > 0) {
                            outsideSidebarElementClick = OutsideElementClick(
                                scope,
                                el.find('.dimensions-sidebar'),
                                () => {
                                    scope.view?.sidebarModel?.toggle.close();
                                },
                            );
                        }

                        escapeKeyWatcherCleanup = initEscapeKeyWatcher();
                    });
                });
            },
        }),
    ]);
